import { PeraWalletConnect } from "@perawallet/connect";
import getBrowserFingerprint from 'get-browser-fingerprint';
import algosdk from "algosdk"
import axios from "axios";
import { encode } from "@msgpack/msgpack";
import Cookies from 'js-cookie'
import store from "../vuex";

//const stdlib = loadStdlib("ALGO");

//const algosdk = require('algosdk');
//const OneSignal = require('onesignal-node');

const algodToken = "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
const algodServer = "https://mainnet-api.algonode.cloud";
const algodPort = 443;

let algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort);


var Pera = {
    peraWallet: null,
    addr: null,
    sessionTxID: null,
    sessionGuid: null,
    context: null,
};

Pera.connect = async function(context){
    this.context = context;
    context.wallet = this;
    
    var me = this;

    this.peraWallet = new PeraWalletConnect({
        network: "mainnet",
        chainId: 4160,
        shouldShowSignTxnToast: false,
      });

      const persistedWC = JSON.parse(localStorage.getItem('walletconnect') || '{}');


    //set 
    if ( context.sessionTxID && 
         context.sessionGuid &&
        persistedWC.connected ) {
      this.context.showWallet = false;
      this.context.connected = false;
      this.context.busy = false;
    

        this.addr = await this.persistConnection()

      console.log("ADDR: " + this.addr)

        //reconnect it up
        var r =  await this.reconnect(context);

        console.log("Reconnected:");
        console.log(r);

        return r;
      
    } else {
        console.log("no addr")
        //if you can't get the addr, then you aren't connected, start over
    
        if(persistedWC.connected){
          this.disconnect();
        }

        this.peraWallet
        .connect()
        .then(async (newAccounts) => {
          // Setup the disconnect event listener
          me.peraWallet.connector?.on("disconnect", me.peraWallet.disconnect);
      
          me.addr = newAccounts[0];
          const id = getBrowserFingerprint();
    
          //if(!this.sessionGuid){
            await me.doubleAuth(this.addr, id)
    
          //setAccountAddress(newAccounts[0]);
        })
        /*
        .reject((error) => {
          // You MUST handle the reject because once the user closes the modal, deflyWallet.connect() promise will be rejected.
          // For the async/await syntax you MUST use try/catch
          if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
            // log the necessary errors
            console.log(error)
          }
        });
*/


    return this;    }
}

Pera.disconnect = async function(){
    Cookies.set("walletType", "");
    Cookies.set("walletID", "");
    Cookies.set("sessionTxID", "");
    Cookies.set("sessionGuid", "");
    
    this.peraWallet.disconnect()
    return true;
}

Pera.reconnect = async function(context){
  this.context = context;
  this.context.wallet = this;

  this.peraWallet = new PeraWalletConnect({
    network: "mainnet",
    chainId: 4160,
    shouldShowSignTxnToast: false,
  });


  this.addr = this.context.addr = Cookies.get("walletID");
  console.log("reconnect: " + this.addr)
  if(this.addr==null || this.addr ==='undefined'){
    console.log("ADDR on Reconnect")
    this.disconnect();
  } else {
    console.log("This is real ADDR")
    console.log(this.addr)

    console.log("Somewhere")
    this.sessionTxID = this.context.sessionTxID = Cookies.get("sessionTxID");
    console.log("Over")
    
    this.sessionGuid = this.context.sessionGuid = Cookies.get("sessionGuid");
    console.log("The")
    this.walletType = this.context.walletType = "Defly";
    console.log("Rainbow")
      
    store.commit("SET_SESSION_GUID", this.sessionGuid);
    store.commit("SET_SESSION_TXID", this.sessionTxID);
    store.commit("SET_WALLETID", this.addr);    

    //Fill the pipe
    this.context.ListMessages();

    // On the every page refresh
    this.peraWallet.reconnectSession().then((accounts) => {

      // Setup the disconnect event listener
      this.peraWallet.connector?.on("disconnect", this.peraWallet.disconnect);
    
      if (accounts.length) {
        this.addr = accounts[0];
      }
  
  
      this.context.showWallet = false;
      this.context.connected = false;
      this.context.busy = false;
  
      //Fill the pipe
      //this.context.ListMessages();
  
      return this;
    }); 
  }
}
    

Pera.doubleAuth = async function(walletID, id){
    console.log("DOUBLE AUTH")
    var authParams = {"walletID":walletID,"id":id}

    //todo: return auth tx from go
    axios.post('.netlify/functions/Auth',authParams)
    .then(async response => {
      await this.sendAuthTx(response)
      
    })
    .catch(error => {
      console.log("ERRROR")
      //thisthis.logAction("DoubleAuth", authParams, error)
      console.log(error)
    })
}

Pera.sendAuthTx = async function(response){

    this.sessionGuid = response.data.guid

    //todo, move this to golang
    var publicAuthToken = {
          "service":"engagr.xyz",
          "desc":"A service for promotional content",
          "authAcc":response.data.walletID,
          "nonce":response.data.authToken,
        }

        // Construct the transaction
        let params = await algodClient.getTransactionParams().do();
                  
        let note = encode(JSON.stringify(publicAuthToken))
        let noteBytes = new TextEncoder("utf-8").encode("arc14" + note)

        let txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          from: this.addr, 
          to: this.addr, 
          fee:0,
          amount:0,
          firstValid:1,
          lastValid:1,
          genesisID: "arc14-auth",
          genesisHash : "f20b122eb226626b5c0d36cd33edcdb94613820f048baf2a1d6dfe46e5be18d1",
          note: noteBytes,
          suggestedParams: params
      });

      try{
        var txID = await this.sign( [txn] );
        if(txID == null){
            console.log("NO TX ID")
            return null;
        }
        await algosdk.waitForConfirmation(algodClient, txID, 4);

        //Get the completed Transaction
        this.sessionTxID = txID

        console.log("SETTINs")
        console.log(this.context.walletType)

        //set COokie for login
        Cookies.set("walletID", this.addr, {sameSite: 'None', secure: true, expires: 30});
        Cookies.set("walletType", "Defly", {sameSite: 'None', secure: true, expires: 30});
        Cookies.set("sessionTxID", txID, {sameSite: 'None', secure: true, expires: 30});
        Cookies.set("sessionGuid",this.sessionGuid, {sameSite: 'None', secure: true, expires:30});

        this.context.$store.commit("SET_SESSION_GUID", this.sessionGuid);
        this.context.$store.commit("SET_SESSION_TXID", txID);
        this.context.$store.commit("SET_WALLETID", this.addr);

        this.context.wallet = this;
        this.context.addr = this.addr;
        this.context.sessionGuid = this.sessionGuid;
        this.context.sessionTxID = txID;

        console.log(this);

        this.context.ListMessages();

        console.log("After List")

      }
      catch(ex){
        return;
      }
  }

  Pera.sign = async function(txs){
    
    try{
        console.log("TXNS")
        console.log(txs)
        const groupID = algosdk.computeGroupID(txs)

        var packagedList = [];

        for( var i=0; i<txs.length; i++)
        {
          //add group
          txs[i].group = groupID

          //package for wallet
          packagedList.push(
          { txn: txs[i], signers: [this.addr] }
         );
        }
        console.log("packaged")
        console.log(packagedList)

        try{
          const signedTxns = await this.peraWallet.signTransaction([packagedList]);
          const signedBytes = signedTxns.map(arr => Uint8Array.from(arr));
        
          var txns = await algodClient.sendRawTransaction(signedBytes).do();
          console.log("Finished Sign")
          return txns.txId;

        }
        catch(ex)
        {
          console.log(ex)
          //this.logAction("SignTransaction->Pera",packagedList, ex)
          return null;
        }

      } catch (ex){
        console.log(ex)
        //this.logAction("SignTransaction->coudn't sign",packagedList, ex)
        return null;
      }
     
    }
    
    Pera.persistConnection = async function(){
      console.log("Persist Connection")
  
      try {

      // On the every page refresh
          this.peraWallet.reconnectSession().then((accounts) => {
  
              // Setup the disconnect event listener
              this.peraWallet.connector?.on("disconnect", this.disconnect);
          
              if (accounts.length) {
                  console.log("reconnected")
                  this.addr = accounts[0]
                  return accounts[0]
              } else {
                  //blow up
                  this.disconnect();
                  console.log("No account set, start over.")
                  return null;
              }
          });
  
        } 
        catch(ex) {
          console.log("could not reconnect")
          console.log(ex)
          /*
          this.deflyWallet
          .connect()
          .then((accounts) => {
            this.peraWallet.connector.on("disconnect", this.disconnect);
  
            return accounts[0];
          })
          */
        }
    }




export default Pera;
