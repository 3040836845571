import Pera from "./Pera.js";
import Defly from "./Defly.js";


var WalletBroker = {
    walletType: ""
}
WalletBroker.connect = async function(typeOfWallet, context){
    context.walletType = typeOfWallet

    switch(typeOfWallet){
        case "Pera":
            return await Pera.connect( context )
        case "Defly":
            return await Defly.connect( context )
        default:
            return null;
    }
}

WalletBroker.disconnect = async function(){
    
    switch(this.walletType){
        case "Pera":
            return await Pera.disconnect()
        case "Defly":
            return await Defly.disconnect()
        default:
            return null;
    }
}

WalletBroker.reconnect = async function( context ){
    switch(context.walletType){
        case "Pera":
            return await Pera.reconnect(context)
        case "Defly":
                return await Defly.reconnect(context)
        default:
            return null;
    }
}
export default WalletBroker
