<template>
<div>
  <v-toolbar
                color="purple lighten-1"
                dark
              >
    <v-toolbar-title><v-icon color="white">mdi-account-plus</v-icon> My Rewards </v-toolbar-title>
    <v-spacer></v-spacer>

    <!--v-chip
      class="ma-2"
      color="purple darken-3"
      label
      text-color="white"
    >
      <v-icon left>
        mdi-account-plus
      </v-icon>
      Showing last 15 Rewards
    </v-chip>
    <v-spacer></v-spacer-->


    <v-btn
        icon
        color="white"
        @click="NotGrouped"
      >
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        @click="Grouped"
      >
        <v-icon>mdi-format-list-text</v-icon>
      </v-btn>

    </v-toolbar>
  
    <v-container full-width style="width:100%">
      <v-row>
        <v-col>
          <div class="h3 text-center">{{allTime}}Ⱥ</div>
          <div class="body-1 text-uppercase">all time</div>
        </v-col>
        <v-col>
          <div class="h3 text-center">{{last30}}Ⱥ</div>
          <div class="body-1 text-uppercase">last thirty days</div>
        </v-col>
        <v-col>
          <div class="h3 text-center">{{last7}}Ⱥ</div>
          <div class="body-1 text-uppercase">last seven days</div>
        </v-col>
      </v-row>
     </v-container>


  <v-data-table
    :headers="headers"
    :items="messages"
    item-key="paymentID"
    class="elevation-1"
    :group-by="groups"
    @click:row="ClickRow"
    hide-default-footer
    disable-pagination
  >
  <template v-slot:[`group.header`]="{items, isOpen, toggle }">
      <th colspan="4">
          <v-icon  @click="toggle"
            >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          <AddressCard :addr="item.sender"></AddressCard>

        {{ items[0].domain }}
      </th>
    </template>

    <template v-slot:[`item.sender`]="{ item }">
      <AddressCard :addr="item.sender"></AddressCard>
    </template>

    <template v-slot:[`item.sendDate`]="{ item }">
        <span>{{ FormatDate(item.sendDate) }}</span>
    </template>

    <template v-slot:[`item.paidDate`]="{ item }" >
        <span>
          <a :href="GetURL(item.paidID)" target="new">{{ FormatDate(item.paidDate) }}</a>
        </span>
    </template>

    <template v-slot:[`item.subject`]="{ item }">
       <span>{{item.subject}}</span>
    </template>

    <template v-slot:[`item.reward`]="{ item }">
      <span class="green--text" style="text-align:left;">{{ item.reward }} Ⱥ</span>
    </template>



</v-data-table>
</div>

</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import * as moment from 'moment'
import axios from "axios";
import AddressCard from "./Address-Card.vue";


export default {
  props:["ForceRefresh","walletID","sessionGuid","sessionTxID",],
  components: {
    AddressCard
  },

  data(){
    return {
      allTime: 0,
      last30: 0,
      last7: 0,
      messages:[],
      isLoading: false,
      groups: [],
      headers: [
      {
            text: 'From',
            align: 'start',
            value: 'sender',
            groupable: true,
            width: '175px',
            class: 'purple lighten-4',
          },
          { text: 'Send Date', value: 'sendDate', align: 'left', groupable: false, width:"175px", class: 'purple lighten-4'},
          { text: 'RewardDate', value: 'paidDate', align: 'left', groupable: false, width:"175px", class: 'purple lighten-4'},
          { text: 'Subject', value: 'subject', align: 'left', groupable: false,class: 'purple lighten-4'},
          { text: 'Reward', width:'150px',value: 'reward', align: 'right', groupable:false,class: 'purple lighten-4' },
      ],
    }
  },
  mounted(){
    this.LoadRewards();
  },
  watch:{
    isLoading(newValue, oldValue){
      if(newValue == false){
        console.log("Done loading" + oldValue)
      } else {
        console.log("Not done")
      }
    },
  },
  methods: {
    LoadRewards(){
      try{
        var r = localStorage.getItem("rewardsMessages")
        if(r){
          this.messages = JSON.parse(r)
        }
      } catch (ex){
        console.log(ex)
      }



      var me = this
      axios.get('.netlify/functions/List', {
          headers: {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.walletID,
              'view': "rewards"
            }
          })
      .then(response =>  {
          me.messages = response.data
          localStorage.setItem("rewardMessages", JSON.stringify(response.data))
        })

      axios.get('.netlify/functions/List', {
          headers: {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.walletID,
              'view': "rewardsTotal"
            }
          })
      .then(response =>  {
        this.allTime = response.data.allTime;
        this.last30 = response.data.rolling30Day;  
        this.last7 = response.data.rolling7Day;  
      })
  
      .catch(error => {
        console.log(error)
      })
    },
    GetURL(paidID){
      return "https://algoexplorer.io/tx/" + paidID
    },
    Display(){

    },

    Grouped(){
      this.groups = "sender";
    },
    NotGrouped(){
      this.groups = [];
    },
    Refresh(){
      this.$emit("Refresh");
    },
    ShowCreate(){
      console.log("Sending the love")
      this.$emit('showCreate')
    },

    async LoadAvatars(messages){
      console.log("Loading Avatars: " + this.messages.length)
      for(var m in this.messages)
      {
        let id = this.messages[m].sender;
        await this.$store.dispatch('fetchProfile', { walletID: id })
      }
      //this.$emit("MessagesLoaded");
    },
    IsBold(item){
      return "grey--text";
    },
    TrimWallet: function (val) {
      let walletID = "" + val.sender
      
      return walletID.substring(0,2)
    },
    GetProfile( item ){
/*
      if(item.sender == null){
        return null;
      }
      let profile = this.$store.getters.getProfile(item.sender)
      //console.log(profile)

      if(profile){
        return profile
      }
      */
      return null;

      /*
      if(item.sender == null){
        return null;
      }
      let profile = this.$store.getters.getProfile(item.sender)
      //console.log(profile)

      if(profile){
        return profile
      }

      //return null
      
//      let url = "https://api.nf.domains/nfd/address?address="+ item.sender +"&view=thumbnail&limit=1"
//      let amx = {}
//      axios.get(url).then(response => amx = response.data)
//      console.log(amx)
      return null;
      */
      
    },
    ShowAvatar(item, index){
      /*
      var profile = this.GetProfile(item)
     
      if(profile){
        return true
      }

      
      
      let url = "https://api.nf.domains/nfd/address?address="+ item.sender +"&view=thumbnail&limit=1"

    axios.get(url).then((response) => {
      
//      this.$store.commit("SET_PROFILE",response.data[0])
      item.avatarURL = response.data[0].properties.userDefined.avatar;
      //this.$emit("RefreshRow");
      //this.$vue.set(this.Messages, index, item)
      return true
    });
*/
    console.log(item.avatarURL)
    return true;
    },
    GetAvatar(){

      let prof = this.GetProfile(this.addr)

      if( prof != null){
        return prof.properties.userDefined.avatar;
      }
      else{
        return "";
      }
    },
    GetAvatar(item, index){
      console.log(item)
      console.log(index)


      /*
       let prof = this.GetProfile(item)

      console.log(prof)

      if( prof != null){
        return prof.properties.userDefined.avatar;
      }
      else{
        */
      //}
    },
    TrimContent: function (val) {

      let walletID = "" + val.messageDeets.b
      walletID = walletID.replace(/<\/?[^>]+(>|$)/g, "");

      return walletID.substring(0,90)
    },
    ClickRow: function ($event) {
      this.$emit("MessageSelected",  $event);
    },
    FormatDate: function (val) {
      //2022-12-11T01:32:13.016354Z
      return moment(val).fromNow()
    },
  },
};
</script>

<style lang="scss">
thead.v-data-table-header th[role=columnheader] {
  font-size: 14px !important;
}
thead.v-data-table-header tD {
  font-size: 14px !important;
}
</style>

