<template>
    <table>
      <tr>
        <td width="44"><v-avatar size="32"><v-img :src="imageURL" :width="width"></v-img></v-avatar></td>
        <td v-if="label=='true'">{{ CutLength(profileName) }}</td>
      </tr>
    </table>
</template>

<script>
import axios from "axios";

export default {
  props: ["sender","width","label"],
  data: () => {
    return {
      imageURL: require("../../src/assets/engagr-profile.png"),
      profileName: "",
      maxLength: 16,
    }
  },
  mounted(){
    if(this.sender)
      this.LoadAvatar();
  },
  watch:{
    sender: function(newAddr, oldAddr) {
      if(newAddr != oldAddr){
        this.LoadAvatar()
      }
    }

  }, 
  methods: {
        async LoadAvatar(){
          var profile = await this.$store.dispatch('fetchAddress', this.sender )

          //get rid of default condition
          if(profile===null) return;

          this.profileName = (profile)?profile.name:"";
          var me = this;

          if(profile.name == profile.owner) return;

          if(profile.properties && profile.properties.verified){
            var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view': "nftsrc"
            }

            var message_payload = {
                "asaid": parseInt(profile.properties.verified.avatarasaid)
            }            
            axios.post(".netlify/functions/Message",message_payload, { headers: headers} )
            .then(async function (response) {

              var suffix = response.data.substr(7)
              if(response.data.startsWith("https")){
                me.imageURL = response.data
              } else {
                me.imageURL = "https://ipfs.algonode.xyz/ipfs/" + suffix;    
              }
              return;
            })

          } else {

            //get user defined
            if(profile && profile.properties && profile.properties.userDefined && profile.properties && profile.properties.userDefined.avatar){
              me.imageURL = profile.properties.userDefined.avatar;
            } else {

              try{
                //remove no setups
                if(profile && profile.name && profile.name == profile.owner){
                  return;
                }
              } catch(err){
                console.log(profile)
                console.log(err)
              }

              try{
                //catch unset values
                if(!Object.keys(profile.properties).length){
                  return;
                }
              } catch(err){
                console.log(profile)
                console.log(err)
              }

              console.log(profile)
              console.log(profile.properties.toString().length)
              var avatar = profile.properties.verified.avatar

              //arc69
              var suffix = avatar.substr(7)
              me.imageURL =  "https://ipfs.algonode.xyz/ipfs/" + suffix;
            }

            return;
          }
        },
        CutLength(value){
          if(this.maxLength &&
            value.length > this.maxLength)
            return value.substr(0,this.maxLength) + "…"
          
          return value;
        },
  }
};
</script>

