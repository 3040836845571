<template>
    <div class="pa-0 ma-0">
        <div style="width:100%; height:170px; background-color:white; color:#373232;overflow:scroll;"> 
                <v-container dense width="100%" height="100%"  v-if="tokens.length>0">
                    <v-row>
                        <v-col class="font-weight-bold" style="border-bottom:1px solid #c5c5c5; margin-bottom:15px;">Coin</v-col>
                        <v-col class="font-weight-bold" style="border-bottom:1px solid #c5c5c5; margin-bottom:15px;">Amount</v-col>
                    </v-row>
                    <v-row no-gutters v-for="item in tokens" :key="item.asset.assetId" style="">
                        <v-col cols="6" @click="LoadAsset(item.asset.assetId)" style="border-right:1px solid #c5c5c5;">
                            {{item.asset.name}} 
                        </v-col>
                        <v-col class="text-left" cols="6" style="padding-left:10px;">{{nFormatter(item.amount,3)}}</v-col>
                    </v-row>

                </v-container>
                <v-container v-else>
                    <v-row>
                        <v-col>There are no reward tokens available. Click here to create your own <v-btn x-small @click="Nav('loyalty')">Loyalty Token</v-btn></v-col>
                    </v-row>
                </v-container>
        </div>    


        
        
    </div>
</template>

<script>
import axios from "axios";
import { VueBus } from '../plugins/vuebus.js';


export default {
  props: ["addr","sessionGuid","sessionTxID"],
  data: () => {
    return {
        create: false,
        currentImage: null,
        url: "",
        tokenCount: 1000000,
        unitName: "",
        coinName: "",
        coinDescription: "",
        tokens:[],
    }
  },
  async mounted(){
    console.log("LOYALTY TOKENS MOUNT: " + this.addr)

    await this.$nextTick()
    
    if(!this.addr){
        //VueBus.$emit("Nav","/brands")
    } else {
        this.$store.dispatch("clearTokens");
        this.LoadFungibles();
    }
  }, 
  watch: {
    addr(newv, oldv){
        if(newv != oldv){
            if(!this.addr){
                //VueBus.$emit("Nav","/brands")
            } else {
                this.$store.dispatch("clearTokens");
                this.LoadFungibles();  
            }
        }
    },
  },
  methods: {
        async SelectImage(image){
            this.currentImage = image;
            this.url = URL.createObjectURL(this.currentImage);
        },
        ShowCreate(){
            console.log("show create")
            this.$emit("CreateFT")
            
        }, 
         ClearImage(){
            this.currentImage = null;
            this.url = "";
        },
        HideCreate(){
            this.$emit("CloseFT")
        },
        DeleteToken(AssetID){
            var me = this;
            
            var thing ={
                assetID: parseInt(AssetID)
            }
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "deletefungible",
                'Accept': 'application/json',
            }
            axios.post( ".netlify/functions/Config",
                thing, 
                { 
                    headers: headers
                } )
            .then(async function (response) {
                me.tokens = response.data.data.accountAssets.nodes;
            })

        },
        LoadAsset(assetID){
            window.location='https://algoexplorer.io/asset/' + assetID
        },
        async LoadFungibles(){
            if(!this.addr)
                return "";

            

            this.tokens = await this.$store.dispatch('fetchTokens', this.addr );
        },
        SaveFungible(){
            this.create = false;
            var me = this;

            var payload = {
                "name": this.coinName,
                "unitName": this.unitName,
                "description": this.coinDescription,
                "tokenCount": this.tokenCount,
                "image": this.currentImage
            }

            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "savecoin",
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
            axios.post( ".netlify/functions/Config",
                payload, 
                { 
                    headers: headers
                } )
            .then(async function (response) {
                me.tokens = response.data.data.accountAssets.nodes;            })
        },
        nFormatter(num, digits) {
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "k" },
                { value: 1e6, symbol: "M" },
                { value: 1e9, symbol: "G" },
                { value: 1e12, symbol: "T" },
                { value: 1e15, symbol: "P" },
                { value: 1e18, symbol: "E" }
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            var item = lookup.slice().reverse().find(function(item) {
                return num >= item.value;
            });
            return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
        },
        Nav(path){
            VueBus.$emit("Nav", "/" + path)
        }


    }
};
</script>

