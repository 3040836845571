<template>
    <div class="text-xs-center">
          <v-chip
            pill
            color="light-blue lighten-3"
          >
            <v-avatar left>
              <AvatarView :sender="addr" width="300" label="false"></AvatarView>
            </v-avatar>
            {{profileName}}
          </v-chip>
        
      
        
    </div>
</template>

<script>
import axios from "axios";
import AvatarView from "./Avatar-View.vue";

export default {
  props: ["addr"],
  components:{
    AvatarView
  },
  data: () => {
    return {
      avatarURL: "",
      profileName: ""
    }
  },
  async mounted(){
    await this.LoadBits();
  },  
  watch: {
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
      if(newAddr != oldAddr){
        this.LoadBits()
      }
    }
  },
  methods: {
    async LoadBits(){
      await this.GetDomain();
      await this.GetAvatar();

    },
    GetProfile( item ){
      let profile = this.$store.getters.getProfile(item)

      if(profile){
        return profile
      }

      return null;
    },
    ShowAvatar(item){
      let prof = this.GetProfile(item)

      return (prof != null);
    },
    async GetAvatar(){
        
      let prof = this.GetProfile(this.addr)

      if( prof != null){
        this.avatarURL = this.GetURL(prof)
      }
      else{
        var me = this
        var url = "https://api.nf.domains/nfd/"+ this.addr 
        
        try {
          await axios.get(url).then((response) => {  
            me.avatarURL = me.GetURL(response.data[0])
            return
          })
          .catch(async function(){
            let url = "https://api.nf.domains/nfd/address?address="+ me.addr +"&view=thumbnail&limit=1"

            await axios.get(url).then((response) => { 
              me.avatarURL = me.GetURL(response.data[0])
            })
            .catch(function(hmm){
              console.log("insider Bad")
              console.log(hmm)
            })
          })
          
        } catch (error) {
          console.log("caught in the outer loop")
          console.log(error)          
        }


      }
    },
    GetURL(node)
    {
      console.log(node.properties)
      if( node.properties.verified){
            var avatar = node.properties.verified.avatar
            var suffix = avatar.substr(7)
            return  "https://ipfs.algonode.xyz/ipfs/" + suffix;
          }
          else if(node.properties.userDefined){
            return  node.properties.userDefined.avatar;
          }

    },
    async GetDomain(){

      let prof = this.GetProfile(this.addr)

      if( prof != null){
        this.profileName = prof.name;
      }
      else{
        var url = "https://api.nf.domains/nfd/"+ this.addr 
        var me = this;

        await axios.get(url).then(() => {  
          console.log("DOMAIN IS GOOD")          
        })
        .catch(async function(){
          var url = "https://api.nf.domains/nfd/address?address="+ me.addr +"&view=thumbnail&limit=1"
          me.profileName = me.addr;

          await axios.get(url).then((response) => {  
            me.profileName = response.data[0].name
          });
        });
       }

 
      }
    },
};
</script>

