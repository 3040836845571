<template>
    <v-img :src="imageURL"></v-img>
</template>

<script>
import axios from "axios";

export default {
  props: ["asaid", "sessionGuID","sessionTxID", "walletID","width"],
  data: () => {
    return {
      imageURL: "",
    }
  },
  mounted(){
    console.log("Mounted")
    this.LoadNFT();
  },  
  watch:{
    asaid: function(newID, oldID) {
      if(newID != oldID){
        this.LoadNFT()
      }
    }

  },
  methods: {
        async LoadNFT(){
            var me = this;
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "nftsrc"
            }

            var message_payload = {
                "asaid": parseInt(this.asaid)
            }            
            axios.post(".netlify/functions/Message",message_payload, { headers: headers} )
            .then(async function (response) {
              
              var suffix = response.data.substr(7)
              me.imageURL = "https://ipfs.algonode.xyz/ipfs/" + suffix;
              console.log(me.imageURL)
              
            })

        },
  }
};
</script>

