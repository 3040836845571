<template>
        <div style="background-color:#fff;  margin-top:45px;">
        <v-form>
            <v-card full-height>
                <v-card-text>

            <v-container>
                <v-row>
                    <v-col class="col-12">
                        <div style="position:relative;">
                        <v-img
                            class="text-center"
                            justify="center"
                            @click="document.getElementById('bgUploader').click()"
                            style="position:relative; background-color:#dddddd"
                            height="100"
                            max-width="1000"
                            :src="GetURL(bgUrl)" >
                                <v-chip
                                        style="margin-top:44px;"
                                        label
                                        x-small
                                        color="whtie"
                                        outlined
                                        text-color="white"
                                        >
                                        Select
                                        </v-chip>
                        </v-img>

                            <v-avatar size="100" 

                            class="text-center"
                            style="margin-top:-50px; margin-left:30px;"
                            color="grey"
                            @click="document.getElementById('logoUploader').click()"
                        >
                                    <v-img 
                                    class="text-center"
                                    style="width:100px;"
                                    :src="GetURL(logoUrl)" 
                                    >
                                    <v-chip
                                        label
                                        x-small
                                        outlined
                                        color="white"
                                        text-color="white"
                                        style="margin-left:25px; margin-top:42px;"
                                        >
                                        Select
                                        </v-chip>

                                </v-img>

                                </v-avatar>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="col-6">
                    <v-text-field
                        v-model="brandName"
                        label="Name"
                        :rules="[rules.required]"
                        @change="Validate"
                        prepend-icon="mdi-domain"
                        >
                    </v-text-field>
                    </v-col>
                    <v-col class="col-6" v-if="userType=='brand' || userType=='admin'">
                    <v-text-field
                        :disabled="loaded"
                        v-model="slug"
                        label="Brand Slug"
                        @change="Validate"
                        :rules="[rules.required]"
                        prepend-icon="mdi-earth"
                        >
                    </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="col-12" v-if="userType=='brand' || userType=='admin'">
                        <v-textarea
                            v-model="description"
                            @change="Validate"
                            outlined
                            label="Description"
                            ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="col-12" v-if="userType=='brand' || userType=='admin'">
                        <div class="text-label">Loyalty Card (300x191)</div>
                        <v-img
                            class="text-center"
                            justify="center"
                            @click="document.getElementById('cardUploader').click()"
                            style="position:relative; background-color:#dddddd"
                            height="191"
                            width="300"
                            :src="GetURL(cardUrl)" >
                                <v-chip
                                        style="margin-top:44px;"
                                        label
                                        x-small
                                        color="whtie"
                                        outlined
                                        text-color="white"
                                        >
                                        Select
                                        </v-chip>
                        </v-img>
                    
                    </v-col>
                </v-row>
                
                <v-row v-if="userType=='brand' || userType=='admin'">
                    <v-col class="col-6">
                    <v-text-field
                        v-model="url"
                        label="URL"
                        :rules="[rules.url]"
                        prepend-icon="mdi-web"
                        >
                    </v-text-field>
                    </v-col>

                    <v-col class="col-6">
                    <v-text-field
                        v-model="emailSignup"
                        label="Email Signup"
                        :rules="[rules.url]"
                        prepend-icon="mdi-at"
                        >
                    </v-text-field>
                    </v-col>

                </v-row>
                <v-row style="display:none;">
                    <v-col class="col-12">

                    <v-file-input 
                    id="bgUploader"
                    accept="image/*"
                    prepend-icon="mdi-page-layout-header"
                    label="Background"
                    @change="SelectImage"
                    @click:clear="ClearImage"
                    v-model="bgImage" />

                    <v-file-input 
                    id="cardUploader"
                    accept="image/*"
                    prepend-icon="mdi-page-layout-header"
                    label="Card"
                    @change="SelectCard"
                    @click:clear="ClearCard"
                    v-model="cardImage" />

                    </v-col>
                </v-row>
                <v-row  style="display:none;">
                    <v-col class="col-12">
                        <v-file-input 
                        label="Logo"
                        id="logoUploader"
                        accept="image/*"
                        prepend-icon="mdi-page-layout-header"
                        @change="SelectLogo"
                        @click:clear="ClearLogo"
                        v-model="logoImage" />



                    </v-col>
                </v-row>

                <v-row v-if="userType=='brand' || userType=='admin'">
                    <v-col class="col-6">
                    <v-text-field
                        v-model="linkedIn"
                        label="LinkedIn"
                        prepend-icon="mdi-page-layout-header"
                        >
                    </v-text-field>
                    </v-col>
                    <v-col class="col-6">
                    <v-text-field
                        v-model="twitter"
                        label="X(Twitter)"
                        prepend-icon="mdi-alpha-l-circle-outline"
                        >
                    </v-text-field>
                    </v-col>
                </v-row>
                
                <v-row v-if="userType=='brand' || userType=='admin'">
                    <v-col class="col-12">
                        <v-select
                            v-model="defaultAlgo"
                            :items="tokens"
                            return-object
                            label="Reward Token"
        >
        <template v-slot:selection="{ item }">
            {{ item.name }}<v-chip text-color="white" color="grey" small
              >{{ item.id }}</v-chip>
        </template>

        <template v-slot:item="{  item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                          <span>{{ item.name }}</span>
                          <v-spacer></v-spacer>
                          <v-chip text-color="white" color="grey" small
                          >ASA ID:{{ item.id }}</v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
            </v-select>                    </v-col>
                </v-row> 
            <v-row>
                <v-col class="col-12">
                    <SelectCategories
                        @SetCategories="SetCategories"
                        :taxonomy="selectedTaxonomy"
                    ></SelectCategories>
                </v-col>
            </v-row>  


            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    :disabled="saveDisabled"
                    class="primary"
                    @click="SaveBrand"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>

        </v-form>



        

    </div>
</template>

<script>
import axios from "axios";
import SelectCategories from "./Select-Categories.vue";
import { VueBus } from '../plugins/vuebus.js';



export default {
  props:["addr","sessionGuid","sessionTxID","pushEnabled",],
  components: {
    SelectCategories
  },
  data: () => {
    return {
        document,
        brandName: "",
        slug: "",
        description:"",
        url: "",
        emailSignup: "",
        bgImage: [],
        bgUrl: "", //require("../../src/assets/bgTest.png"),
        logoImage: [],
        logoUrl: "", // require("../../src/assets/engagr-logo.png"),
        cardImage: [],
        cardUrl: "", // require("../../src/assets/engagr-logo.png"),
        logo: "",
        showLogoLabel: false,
        tokens:[],
        taxonomy:[],
        selectedTaxonomy:[],
        defaultAlgo:{},
        twitter:"",
        linkedIn:"",
        loaded: false,
        saveDisabled: true,
        userType:"",
        rules: {
          required: value => !!value || 'Required.',
          url: value => {
            const urlRegex = /^https:\/\.*/i;            
            return urlRegex.test(value) || 'Invalid URL'
          },
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          mobile: value => {
            const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            return pattern.test(value) || 'Invalid mobile.'
          },
        },
    }
  },
  async mounted(){
    await this.$nextTick()

    if(!this.addr){
        VueBus.$emit("Nav","/brands")
    } else {
        this.GetNotifications();
        this.LoadFungibles();
        this.GetBrand();
    }
  },

  watch:{
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
        if(!this.addr){
            VueBus.$emit("Nav","/brands")
        }

        
        if(newAddr != oldAddr){
            //this.GetNotifications();
            this.LoadFungibles();
            this.GetBrand();
        }
    },
    
    pushEnabled: function( newPush){
        console.log(newPush)
        if(newPush == false){
            this.ForceShow();
        }
    },
    
  },
  methods: {
    async LoadFungibles(){
        var me = this;
        var thing ={}
        var headers = {
            'sessionGuID': this.sessionGuid,
            'sessionTxID': this.sessionTxID,
            'walletID': this.addr,
            'view': "getfungibles",
            'Accept': 'application/json',
        }
        axios.post( ".netlify/functions/Config",
            thing, 
            { 
                headers: headers
            } )
        .then(async function (response) {
            console.log("RESPONSE FROM Get Fungibles")
            console.log(response)
            var d = [];
            for(var i=0;i<response.data.data.accountAssets.nodes.length;i++){
                var l = response.data.data.accountAssets.nodes[i]
                var asa = {
                name: l.asset.name,
                id: l.asset.assetId,
                decimalCount: l.asset.decimalCount
                };
                d.push(asa)

                if(me.asaid==l.asset.assetId){
                    me.defaultAlgo = asa;
                }
            }

            me.tokens = d; 
            //this.StageRecords(response.data.data.accountAssets.nodes);
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    GetHeaders(view){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view':view
          }
          return headers;
    },
    SaveEmail(){
        var saveEmail = {
            type: "email",
            contact: this.email
        }

        axios.post(".netlify/functions/Config",saveEmail, { headers: this.GetHeaders("saveemail")} )
            .then(async function (response) {
            console.log("SaveEmail: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    SaveSms(){
        var saveEmail = {
            type: "sms",
            contact: this.sms
        }

        axios.post(".netlify/functions/Config",saveEmail, { headers: this.GetHeaders("savesms")} )
            .then(async function (response) {
            console.log("SaveSms: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    SetCategories(val){
        console.log(val)
      this.selectedTaxonomy = val;
    },
    async SelectImage(image){
            this.bgImage = image;
            this.bgUrl = URL.createObjectURL(this.bgImage);
        },
        async SelectLogo(image){
            this.logoImage = image;
            this.logoUrl = URL.createObjectURL(this.logoImage);
        },
        async SelectCard(image){
            this.cardImage = image;
            this.cardUrl = URL.createObjectURL(this.cardImage);
        },
        ShowCreate(){
            console.log("show create")
            this.create = true;
        }, 
         ClearImage(){
            this.bgImage = null;
            this.bgUrl = "";
        },
        ClearLogo(){
            this.logoImage = null;
            this.logoUrl = "";
        },
        ClearCard(){
            this.cardImage = null;
            this.cardUrl = "";
        },

    async GetNotifications(){
        var t = this;
        
        var d = await t.$store.dispatch("GetNotificationConfig", {})
        
        if(d){
            for(var i=0; i<d.length; i++){
            switch(d[i].type){
                case "email":   t.email = d[i].contact; break;
                case "sms":     t.sms = d[i].contact; break;
            }
        }
        }
        t.ForceShow();
    },
    ForceShow(){
        if(!(this.pushEnabled ||
           this.email == "" ||
           this.sms == "" )){
            this.$emit("ForceShow", true)
        } else {
            //this.$emit("ForceShow", false)
        }
    },
    ShowLabel(){
        console.log(this.showLogoLabel)
        return this.showLogoLabel;
    },
    SaveBrand(){

        var saveBrand = {
            brandName: this.brandName,
            slug: this.slug,
            logoCid: this.logoUrl,  //these need to come before the upload codes
            backgroundCid: this.bgUrl, //these need to come before the upload codes
            cardCid: this.cardUrl, //these need to come before the upload codes


            description: this.description,
            url: this.url,
            emailSignup: this.emailSignup,
            taxonomy: this.selectedTaxonomy,
            linkedIn: this.linkedIn,
            twitter: this.twitter,
            bgImage: this.bgImage,
            asaid: this.defaultAlgo.id,
            asaName: this.defaultAlgo.name,
            logoImage: this.logoImage,
            cardImage: this.cardImage,
            userType: this.userType,
            cashbackURL: ""
        }

        var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "savebrand",
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }

        axios.post(".netlify/functions/Config",saveBrand, { headers: headers} )
            .then(async function (response) {
            console.log("SaveBrand: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    GetBrand(){
        console.log(this.addr)
        var me = this;
        fetch("https://worker.hokr.workers.dev/api/user/" +this.addr+ "/brand")
            .then(r => r.json())
            .then(d => {
                console.log("BRAND IS LOADING")

                me.brandName = d.brandName;
                me.description = d.description;
                me.url = d.url;
                me.linkedIn = d.linkedIn;
                me.slug = d.slug;
                me.emailSignup = d.emailSignup;
                me.twitter = d.twitter;
                me.asaid = d.asaid;
                me.selectedTaxonomy = d.taxonomy;
                me.logoUrl = d.logoCid;
                me.bgUrl = d.backgroundCid;
                me.userType = d.userType;

                console.log(d.userType)

                //set the token, may have to let it load
                for(var i=0; i<me.tokens.length; i++){
                    console.log(d.asaid)
                    console.log(me.tokens[i].id)
                    if(me.tokens[i].id == d.asaid){
                        me.defaultAlgo = me.tokens[i]
                    }
                }
                if(me.slug)
                    me.loaded= true;
                
                me.saveDisabled = false;
            });


       
    },
    GetURL(cid){
        if(cid && cid.startsWith("blob:"))
            return cid;

        let url = "https://worker.hokr.workers.dev/api/brandimages/"+ cid
        return url;
    },
    Validate(){
        if( this.name == "" && this.slug == "" && this.description == ""){
            this.saveDisabled = true;
        } else {
            this.saveDisabled = false;
        }
        
    }
  }
}
</script>

<style></style>