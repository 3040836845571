// src/plugins/vuetify.js

import Vue from 'vue'
import Vuex from 'vuex';
import axios from "axios";


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      Profiles: [],
//      DomainKeyLookup: [],
      NotificationConfig: {},
      Feed: {},
      Posts: {},
      Brands: {},
      Slugs: {},
      StoreItems: {},
      StoreItemsVisible: {},
      Items: {},
      Follows:{
      },
      Sales: [],
      Purchases: [],
      Links: [],
      FollowedBrands:[],
      Tokens: [],
      Threads: {},
      messages: {},
      tracking: {},
      Address: {},
      sessionGuid:"",
      sessionTxID:"",
      walletID: "",
      walletType: "",
      LastThreadPull: {},
    },
    actions: {
        async fetchWalletID(context){
            return await context.rootState.walletID
        },
        async fetchFollow(context, brandID){
            if(context.rootState.FollowedBrands[brandID])
            //if(.prototype.propertyIsEnumerable(brandID))
                return true;
            else
                return false;
        },
        async fetchBrand(context, brandID){
            if( context.rootState.Brands[brandID]) {
                return context.rootState.Brands[ brandID ];
            }

            return  await context.dispatch("GetBrandByID",brandID)
        },
        async fetchIdFromSlug(context, slug){
            if( context.rootState.Slugs[slug]) {
                return context.rootState.Slugs[ slug ];
            }

            return  await context.dispatch("GetIdFromSlug",slug)
        },
        async fetchStoreItemsVisible(context, walletID, overwrite){
            if( context.rootState.StoreItemsVisible[walletID] != null && overwrite) {
                console.log("storeItems")
                return context.rootState.StoreItemsVisible[walletID];
            }

            return  await context.dispatch("GetStoreItemsVisibleByID",walletID)
        },
        async fetchStoreItems(context, walletID, overwrite){
            if( context.rootState.StoreItems[walletID] != null && overwrite) {
                console.log("storeItems")
                return context.rootState.StoreItems[walletID];
            }

            return  await context.dispatch("GetStoreItemsByID",walletID)
        },
        async fetchStoreItem(context, walletID, productID){
            if( context.rootState.StoreItems[walletID][productID]) {
                let composite_key = walletID + "-" + productID
                return context.rootState.Items[ composite_key];
            }

            return  await context.dispatch("GetStoreItemByID",walletID, productID)
        },

        async fetchTokens(context, brandID){
            if( context.rootState.Tokens.length != 0) {
                return context.rootState.Tokens;
            }

            let tokens  =  await context.dispatch("GetTokens",brandID)
            return tokens;
        },

        async clearTokens(context){
            context.rootState.tokens = null;
        },


        async fetchAddress(context, brandID){
            if( context.rootState.Address == null) {
                return context.rootState.Address;
            }

            let address  =  await context.dispatch("GetAddress",brandID)
            return address;
        },

        async fetchNotes(context, txID){
            let txs  =  await context.dispatch("GetNotes",txID)
            return txs;
        },

        async fetchSales(context, brandID){
            //if( context.rootState.Sales == null) {
            //    return context.rootState.Sales;
            //}

            let sales  =  await context.dispatch("GetSales",brandID)
            return sales;
        },

        async fetchPurchases(context, brandID){
/*
            if( context.rootState.Sales == null) {
                return context.rootState.Purchases;
            }
*/
            let purchases  =  await context.dispatch("GetPurchases",brandID)
            return purchases;
        },
        async fetchLinks(context, brandID){
            /*
            if( context.rootState.Sales == null) {
                return context.rootState.Links;
            }
    */
            let links  =  await context.dispatch("GetLinks",brandID)
            return links;
        },
        async fetchFeed(context, brandID, overwrite){
            
            if( context.rootState.Feed[brandID] != null && 
                overwrite) {
                console.log("FetchFeed")
                return context.rootState.Feed[brandID];
            }
    
            let feed  =  await context.dispatch("GetFeed",brandID)
            return feed;
        },
        async fetchItem(context, post){
            if( context.rootState.Posts[post.brandID] != null &&
                context.rootState.Posts[post.brandID][post.postID] != null) {
            
                return context.rootState.Posts[post.brandID][post.postID];
            }
    
            let item  =  await context.dispatch("GetItem", post)
            return item;
        },
        async fetchFollows(context, userID){
            
            if( context.rootState.Follows[userID] ) {
            
                return context.rootState.Follows[userID];
            }
    
            let item  =  await context.dispatch("GetFollows", userID)
            return item;
        },
/*
        async fetchProfile(context, wallet){
            //var wallet = "O4KW62L6WISYL3IW23CVTIIEBFDMQVBXSZXML2RMDQ7DGBAQI6XOHIPOGY";
            //var wallet = "hoover.algo";

            //check if it exists and return
            if( context.state.DomainKeyLookup[ wallet ] !== undefined){
                return context.state.DomainKeyLookup[ wallet ];
            }

            if( context.state.Profiles[ wallet ] !== undefined){
                return context.state.Profiles[ wallet ];
            }

            //check domain first as more common.
            var domain = await context.dispatch("GetByDomain",wallet);

            //check wallet length
            if(!domain && wallet.length==58){
                console.log(context)
                //return context.rootState.GetByWalletID(wallet)
                return  await context.dispatch("GetByWalletID",wallet)
            } else {
                return domain;
            }
        },
        */
        fetchGuid(){
            var dt = new Date().getTime();
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function( c ) {
               var rnd = Math.random() * 16;//random number in range 0 to 16
               rnd = (dt + rnd)%16 | 0;
               dt = Math.floor(dt/16);
               return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
            })        
        },
        /*
        async GetByDomain(context, item){
            var url = "https://api.nf.domains/nfd/"+ item 
    
            var profile = await axios.get(url)
            .then(async function(response){  
                context.commit("UPDATE_PROFILE", response.data)                        
                console.log(response.data)
                return response.data;
            })
            .catch(function(){
                console.log("No domain here: " + item)
                
                return null;
            })

            return profile;

        },
        async GetByWalletID(context, item){
            let url = "https://api.nf.domains/nfd/v2/address?address="+ item +"&view=thumbnail&limit=1"
            try{    
                return await axios.get(url, {  
                    validateStatus: ()=> true })
                .then(async function(response){               
                    if(response.status == 404){
                        return {
                            owner: item,
                            name: item,
                            depositAccount: item

                        };  
                    }

                    if(response.data[item] && response.data[item].length > 0){
                        await context.commit("UPDATE_PROFILE", response.data[item][0])                        
                        return response.data[item][0];
                    }
                })
                .catch((err)=>{
                    console.log("ERROR")
                    console.log(err)
                    
                    if(item.length==58){
                        return {
                            owner: item,
                            name: item,
                            depositAccount: item
                        };    
                    }

                    return Promise.reject(err)

                })
            } catch (err){
                console.log("IS")
                console.log(err)
            }    

        },
        */
        async GetBrandByID(context, item){
            var headers = {};
            let url = "https://worker.hokr.workers.dev/api/user/" + item + "/brand";
            
            return axios.get(url, { headers: headers} )
              .then(async function (response) {
                context.commit("UPDATE_BRAND", response.data)     

                return  response.data;
            })
        },
        async GetFollows(context, uid){
            var headers = {};
            let url = "https://worker.hokr.workers.dev/api/user/" + uid + "/follows";
            
            return axios.get(url, { headers: headers} )
              .then(async function (response) {
                context.commit("UPDATE_FOLLOWS", response.data, uid)     

                return  response.data;
            })
        },
        async GetStoreItemsVisibleByID(context, item){
            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                //'view': "getfungibles",
                'Accept': 'application/json',
            }

            
            let url = "https://worker.hokr.workers.dev/api/user/" + item + "/storevisible";
            
            return axios.get(url, { headers: headers} )
              .then(async function (response) {
                context.commit("UPDATE_STOREITEMSVISIBLE", response.data)     

                return  response.data;
            })
        },


        async GetStoreItemsByID(context, item){
            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                //'view': "getfungibles",
                'Accept': 'application/json',
            }

            
            let url = "https://worker.hokr.workers.dev/api/user/" + item + "/store";
            
            return await axios.get(url, { headers: headers} )
              .then(async function (response) {
                context.commit("UPDATE_STOREITEMS", response.data, item)     

                return  response.data;
            })
        },
        async GetStoreItemByID(context, item, productID){
            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                //'view': "getfungibles",
                'Accept': 'application/json',
            }

            
            let url = "https://worker.hokr.workers.dev/api/user/" + item + "/store";
            
            return axios.get(url, { headers: headers} )
              .then(async function (response) {
                context.commit("UPDATE_STOREITEM", response.data, item, productID)     

                return  response.data;
            })
        },

        async GetIdFromSlug(context, slug){
            var me = this;
            var firstLetter = slug.charAt(0)

            return await fetch("https://worker.hokr.workers.dev/api/slugs/" + firstLetter )
                .then(r => r.json())
                .then(json => {
                me.slugs=json;

                context.commit("UPDATE_SLUGID", this.slugs[slug].toUpperCase(), slug)     

                return this.slugs[slug].toUpperCase()

            })
        },

        async GetTokens(context, brandID){
            if(!brandID)
                return

            var thing = {};
            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'view': "getfungibles",
                'Accept': 'application/json',
            }
            return axios.post( "/.netlify/functions/Config",
                thing, 
                { 
                    headers: headers
                } )
            .then(async function (response) {
                if(response.data.data.accountAssets){
                    let tokens = response.data.data.accountAssets.nodes;
                    context.commit("UPDATE_TOKENS", tokens)   
                    return tokens;
                }              
                
                return null;
            })        
        },
        async GetAddress(context, brandID){
            if(!brandID)
                return

            var thing = {};
            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'view': "getaddress",
                'Accept': 'application/json',
            }
            return axios.post( "/.netlify/functions/Config",
                thing, 
                { 
                    headers: headers
                } )
            .then(async function (response) {
              let address = response.data;
              context.commit("UPDATE_ADDRESS", address)   
              
              return address;
            })        
        },
        async GetNotes(context, txID){

            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
            }
            return axios.get( "https://worker.hokr.workers.dev/api/tx/" + txID + "/notes",
                { 
                    headers: headers
                } )
            .then(async function (response) {
                console.log(response.data)
              let tx = response.data.notes;
              
              return tx;
            })        
        },
        async GetSales(context, brandID){
            if(!brandID)
                return

            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'Accept': 'application/json',
            }
            let url = "https://worker.hokr.workers.dev/api/user/" + brandID + "/sales"

            console.log(url)

            return axios.get( url,
                { 
                    headers: headers
                } )
            .then(async function (response) {
              let sales = response.data;
              context.commit("UPDATE_SALES", sales)   
              
              return sales;
            })        
        },
        async GetPurchases(context, brandID){
            if(!brandID)
                return

            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'Accept': 'application/json',
            }


            let url = "https://worker.hokr.workers.dev/api/user/" + brandID + "/purchases"
            return axios.get( url,
                { 
                    headers: headers
                } )
            .then(async function (response) {
              let purchases = response.data;
              
              context.commit("UPDATE_PURCHASES", purchases)   
              
              return purchases;
            })        
        },
        async GetLinks(context, brandID){
            if(!brandID)
                return

            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'Accept': 'application/json',
            }


            let url = "https://worker.hokr.workers.dev/api/user/" + brandID + "/links"
            return axios.get( url,
                { 
                    headers: headers
                } )
            .then(async function (response) {
              let links = response.data;
              
              context.commit("UPDATE_LINKS", links.reverse())   
              
              return links;
            })        
        },
        async GetFeed(context, brandID){
            if(!brandID)
                return

            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'Accept': 'application/json',
            }

            let url = "https://worker.hokr.workers.dev/api/user/" + brandID + "/brandfeed"
            return axios.post( url,{ actionUserID: context.state.walletID }, { headers: headers} )
            .then(async function (response) {
              let feed = response.data;
              
              context.commit("UPDATE_FEED", feed.reverse(), brandID)   
              
              return feed;
            })        
        },
        async GetItem(context, post){
            if(!post.brandID)
                return

            var headers = {
                'sessionGuID': context.state.sessionGuid,
                'sessionTxID': context.state.sessionTxID,
                'walletID': context.state.walletID,
                'Accept': 'application/json',
            }
/*
            console.log(post.brandID)

            var url = "https://worker.hokr.workers.dev/api/user/"+ post.brandID +"/post/" + post.postID  

            return await fetch(url)
                .then(function(r){ console.log(r); return r.json()})
                .then(json => {
                  context.commit("UPDATE_POST", json, post)
                  return item;
                })
                .catch(function(err){console.log(err)})
*/
            let url = "https://worker.hokr.workers.dev/api/user/"+ post.brandID +"/post/" + post.postID  
            return axios.get( url, { headers: headers} )
            .then(async function (response) {
              let item = response.data;

              context.commit("UPDATE_POST", item)   
              
              return item;
            })    
                
        },
/*
        async updateMessage(context, message){
            var key = message.addr + ">>" + message.threadBuddy;

            await axios.get('.netlify/functions/List?messageID=' + message.messageID, {
                headers: {
                    'sessionGuID': context.state.sessionGuid,
                    'sessionTxID': context.state.sessionTxID,
                    'walletID': context.state.walletID,
                    'threadbuddy': message.threadBuddy,
                    'view': "singlemessage",
                    'Content-Type': 'application/json'
                }}
            )
            .then(response =>  {

                var base = context.state.Threads[key];
                console.log("Base")
                console.log(base)

                if(!base) base = {
                    messages: [],
                    tracking: {},
                };

                var threadBall = response.data;

                //walk Tracking
                for(var id in threadBall.tracking){
                    if(!(id in base.tracking))
                    {
                        base.tracking[id] = threadBall.tracking[id]
                    }
                }

                context.commit("UPDATE_TRACKING_FOR_MESSAGE", {buddy:key, threadList: response.data})                        

                return base;//response.data
            })
            .catch(function(ex){
                console.log(ex)
            }) 
        },

        async updateThread(context, addr){
            console.log("GET THREAD CALLED")
            var requestTime = null;
            var key = addr.addr + ">>" + addr.threadBuddy;
                console.log(key);
            if(key in context.state.Threads)  
            {
                console.log("INSIDE GET THREAD")
                console.log(context.state.Threads) 
                console.log(context.state.Threads[key]) 
                return context.state.Threads[key]
            }
            else 
            {

               if(!requestTime)
                requestTime = Date.now();

               let list = await axios.get('.netlify/functions/List?requestTime=' + requestTime + '&loadAll=' + addr.loadAll, {
                    headers: {
                        'sessionGuID': context.state.sessionGuid,
                        'sessionTxID': context.state.sessionTxID,
                        'walletID': context.state.walletID,
                        'threadbuddy': addr.threadBuddy,
                        'view': "messageThread",
                        'Content-Type': 'application/json'
                    }}
                )
                .then(response =>  {

                    var base = context.state.Threads[key];
                    if(!base) base = {
                        messages: [],
                        tracking: {},
                    };

                    var threadBall = response.data;

                    //walk Messages
                    for(var i=0; i<threadBall.messages.length; i++){
                        base.messages.push(threadBall.messages[i])
                    }

                    //walk Tracking
                    for(var id in threadBall.tracking){
                        if(!(id in base.tracking))
                        {
                            base.tracking[id] = threadBall.tracking[id]
                        }
                    }

 
                    context.commit("UPDATE_THREAD", {addr:addr.addr, buddy:addr.threadBuddy, threadList: response.data})                        
                    context.commit("UPDATE_THREADPULL", {buddy:key, threadPull: Date.now()})

                    context.state.Threads[key]

                    return base;//response.data
                })
                .catch(function(ex){
                    console.log(ex)
                }) 
                
                return list;
            }

        },
        */
        async GetNotificationConfig(context){
            console.log("Notification Config")
            var t = this;
            return await axios.post(".netlify/functions/Config",{}, { headers: {
                    'sessionGuID': context.state.sessionGuid,
                    'sessionTxID': context.state.sessionTxID,
                    'walletID': context.state.walletID,
                    'view': "getnotifications",
                    'Content-Type': 'application/json'
                }
            
            } )
            .then(async function (response) {
                t.NotificationConfig = response.data;

                return t.NotificationConfig
            })
            .catch(function(d){
                console.log(d)
            })
        },
    },
    mutations:{
        UPDATE_PROFILE(state, profile) {            
            var addr = profile.owner;
            var domain = profile.name;

            state.Profiles[addr] = profile;
            state.DomainKeyLookup[domain] = profile;
        },
        UPDATE_BRAND(state, profile) {            
            var senderID = profile.senderID;

            state.Brands[senderID] = profile;
        },
        UPDATE_TOKENS(state, tokens) {            
            state.Tokens = tokens;
        },
        UPDATE_THREAD(state, threadBall){

            var key = threadBall.addr + ">>" + threadBall.buddy;
            if(!(key in state.Threads)){
                state.Threads[key] = {
                    messages: [],
                    tracking: {},
                };
            }
            
            var base = state.Threads[key]

            //walk Tracking
            for(var id in threadBall.threadList.tracking){
                
                if(!(id in base.tracking))
                {
                    base.tracking[id] = threadBall.threadList.tracking[id]
                    console.log(state.Threads[key])
                }
            }

            //walk Messages
            console.log(state)
            for(var i=0; i<threadBall.threadList.messages.length; i++){
                base.messages.push(threadBall.threadList.messages[i])
            }

            console.log(state)
        },
        UPDATE_TRACKING_FOR_MESSAGE(state, threadBall){
            //need key

            let base = state.Threads[threadBall.buddy]
            
            if(!base) base = {
                messages: [],
                tracking: {},
            };

            //walk Tracking
            for(var id in threadBall.threadList.tracking){
                if(!(id in base.tracking))
                {
                    base.tracking[id] = threadBall.threadList.tracking[id]
                }
            }

        },
        UPDATE_MESSAGES(state, threadBall){
            console.log(state)
            console.log(threadBall)
        },
        ADD_ONE_MESSAGE(state, threadBall)
        {
            console.log("ADD ONE MESSAGE")
            console.log(threadBall)

            var key = threadBall.addr + ">>" + threadBall.buddy;
            console.log(key)

            //base did not print out
            if(!(key in state.Threads)){
                state.Threads[key] = {
                    messages: [],
                    tracking: {},
                }
            }

            var base = state.Threads[key]
            console.log(base)

;            var messageExists = false;



            //walk Tracking            
            if(!(threadBall.message.assetID in base.tracking))
            {
                base.tracking[threadBall.message.assetID] = threadBall.tracking
            }

            console.log("tracking added")

            //walk Messages to ensure no duplicates
            for(var i=0; i<base.messages.length; i++){
                if(base.messages[i].assetID == threadBall.message.assetID){
                    messageExists = true;
                }
            }
            
            //no dupes added
            if(messageExists == false)
            {
                var len = base.messages.length;
                Vue.set(base.messages, len, threadBall.message)
                //base.messages.push(threadBall.message)
            }
            console.log("message added")
            console.log(state.Threads)

        },

        UPDATE_TRACKING(state, threadBall){
            state.Tracking[threadBall.buddy] = threadBall.threadList;
        },
        UPDATE_THREADPULL(state, threadPull){
            state.LastThreadPull[threadPull.buddy] = threadPull.threadPull;
        },
        SET_SESSION_GUID(state, sessionGuid){
            state.sessionGuid = sessionGuid
        },
        SET_SESSION_TXID(state, sessionTxID){
            state.sessionTxID = sessionTxID
        },
        SET_WALLETID(state, walletID){
            state.walletID = walletID
        },
        SET_WALLETTYPE(state, walletType){
            state.walletType = walletType;
        },
        SET_FOLLOWEDBRANDS(state, followedBrands){
            state.FollowedBrands = followedBrands;
        },
        UPDATE_SLUGID(state, id, slug){
            state.Slugs[slug] = id;
        },
        UPDATE_STOREITEMS(state, items, id){
            for(var productID in items){
                let composite_key = id + "-" + productID
                state.Items[composite_key] = items[productID];
            }

            state.StoreItems[id] = items;
        },

        UPDATE_STOREITEMSVISIBLE(state, items){
            state.StoreItemsVisible = items;
        },

        UPDATE_STOREITEM(state, item, id, productID){
            let composite_key = id + "-" + productID
            state.Items[composite_key] = item;
        },
        UPDATE_ADDRESS(state, item){
            state.Address = item;
        },
        UPDATE_SALES(state, sales){
            state.Sales = sales;
        },
        UPDATE_PURCHASES(state, purchases){
            state.Purchases = purchases;
        },
        UPDATE_LINKS(state, links){
            state.Links = links;
        },
        UPDATE_FEED(state, feed, brandID){
            state.Feed[brandID] = feed;
        },  
        UPDATE_FOLLOWS(state, feed, brandID){
            state.Follows[brandID] = feed;
        },  

        UPDATE_POST(state, record){

            let feed = state.Posts[record.sender];
            if(!feed){
                state.Posts[record.sender] = {}
            }
            state.Posts[record.sender][record.id] = record;
            
        },

    },
    getters:{
        getProfile: (state) => (id) => {
            if(id in state.Profiles)
                return state.Profiles[id]
            
            return null;
        },

    
    },
  })
  
export default store;