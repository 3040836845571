<template>
    <v-container style="margin-top:45px;">
        <v-row>
            <v-col>
                <v-card color="white">
                <v-card-title>Notification Preferences</v-card-title>
                <v-card-subtitle>Select one or more notification options</v-card-subtitle>
                <v-card-text>
                    <v-form>
                        <v-container>
                            <v-row style="padding-bottom:40px;">
                                <v-col class="col-9">
                                <v-text-field
                                    v-model="email"
                                    label="Email"
                                    :rules="[rules.email]"
                                    prepend-icon="mdi-email-open"
                                    >
                                </v-text-field>

                                <v-checkbox v-model="emailNotify" label="Please send me emails about brands that I follow"></v-checkbox>
                                </v-col>
                                <v-col align-self="end" class="col-3" ><v-btn @click="SaveEmail" width="100" color="purple lighten-4">Save</v-btn></v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row  style="padding-top:40px;">
                                <v-col class="col-9">
                                <v-text-field
                                    v-model="sms"
                                    :rules="[rules.mobile]"
                                    label="SMS"
                                    prepend-icon="mdi-message-processing"
                                    >
                                </v-text-field>
                                <v-checkbox v-model="smsNotify" label="Please send me SMS about brands that I follow"></v-checkbox>

                                </v-col>
                                <v-col align-self="end" class="col-3" ><v-btn width="100" @click="SaveSms" color="purple lighten-4">Save</v-btn></v-col>
                            </v-row>
                        </v-container>
                    </v-form>



                    

                </v-card-text>
                <v-card-actions>

                </v-card-actions>
                </v-card>

            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import axios from "axios";
import { VueBus } from '../plugins/vuebus.js';



export default {
  props:["addr","sessionGuid","sessionTxID","pushEnabled",],
  data: () => {
    return {
        email: "",
        emailNotify: true,
        sms: "",
        smsNotify: true,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          mobile: value => {
            const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            return pattern.test(value) || 'Invalid mobile.'
          },
        },
    }
  },
  async mounted(){
    await this.$nextTick()

    if(!this.addr){
        VueBus.$emit("Nav","/brands")
    } else {
        await this.GetNotifications();
    }
  },
  watch:{
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
        if(!this.addr){
            VueBus.$emit("Nav","/brands")
        }
        
        if(newAddr != oldAddr){
            this.GetNotifications();
        }
    },
    
    pushEnabled: function( newPush){
        console.log(newPush)
        if(newPush == false){
            this.ForceShow();
        }
    },
    
  },
  methods: {
    GetHeaders(view){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view':view
          }
          return headers;
    },
    SaveEmail(){
        var saveEmail = {
            type: "email",
            contact: this.email,
            preference: this.emailNotify
        }

        axios.post(".netlify/functions/Config",saveEmail, { headers: this.GetHeaders("saveemail")} )
            .then(async function (response) {
            console.log("SaveEmail: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    SaveSms(){
        var saveEmail = {
            type: "sms",
            contact: this.sms,
            preference: this.smsNotify
        }

        axios.post(".netlify/functions/Config",saveEmail, { headers: this.GetHeaders("savesms")} )
            .then(async function (response) {
            console.log("SaveSms: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    async GetNotifications(){
        var t = this;
        
        var d = await t.$store.dispatch("GetNotificationConfig", {})
        if(d.length && d.length > 0){
            for(var i=0; i<d.length; i++){
                switch(d[i].type){
                    case "email":   
                        t.email = d[i].contact; 
                        t.emailPreference = d[i].preference; 
                        break;
                    case "sms":     
                        t.sms = d[i].contact; 
                        t.smsPreference = d[i].preference; 
                        break;
                }
            }

        }
        t.ForceShow();

        /*
        axios.post(".netlify/functions/Config",{}, { headers: this.GetHeaders("getnotifications")} )
        .then(async function (response) {
            var d = response.data;
            
            for(var i=0; i<d.length; i++){
                switch(d[i].type){
                    case "email":   t.email = d[i].contact; break;
                    case "sms":     t.sms = d[i].contact; break;
                }
            }
            t.ForceShow();
        })
        .catch(function (error) {
            console.log(error);
        });
        */
    },
    ForceShow(){
        if(!(this.pushEnabled ||
           this.email == "" ||
           this.sms == "" )){
            this.$emit("ForceShow", true)
        } else {
            //this.$emit("ForceShow", false)
        }
    }    
  }
}
</script>

<style></style>