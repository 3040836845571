<template>
    <v-card>
      <v-card-title>Notification Preferences</v-card-title>
      <v-card-subtitle>Select one or more notification options</v-card-subtitle>
      <v-card-text>
        <v-form>
            <v-container>
                <v-row>
                    <v-col class="col-12">
                    <v-text-field
                        v-model="email"
                        label="Email"
                        :rules="[rules.email]"
                        prepend-icon="mdi-email-open"
                        @change="SetEmail"
                        >
                    </v-text-field>
                    <v-checkbox @change="SetEmailPref" v-model="emailNotify" label="Please send me emails about brands that I follow"></v-checkbox>

                    </v-col>
                </v-row>
                <v-row style="padding-bottom:40px;">
                    <v-col class="col-12" style="color:red">&nbsp;{{emailError}}</v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row style="padding-top:40px;">
                    <v-col class="col-12">
                    <v-text-field
                        v-model="sms"
                        :rules="[rules.mobile]"
                        label="SMS"
                        prepend-icon="mdi-message-processing"
                        @change="SetSms"
                        >
                    <v-checkbox @change="SetSmsPref" v-model="smsNotify" label="Please send me emails about brands that I follow"></v-checkbox>

                    </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  class="col-12"  ><div style="padding-top:40px;" class='onesignal-customlink-container'></div></v-col>
                </v-row>
            </v-container>
        </v-form>



        

      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>

</template>

<script>
import axios from "axios";


export default {
  props:["addr","sessionGuid","sessionTxID","pushEnabled",],
  data: () => {
    return {
        email: "",
        emailNotify: true,
        emailError: "",
        sms: "",
        smsNotify: true,

        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          emailOnServer: value => { 
            
            var message_payload = {
                email: value,
            }

            return axios.post( "/.netlify/functions/Validation",message_payload, { headers: null} )
            .then(async function (response) {
                console.log(response)
                switch(response.data.result){
                    case "Active":
                    case "Inactive":
                    case "Engaged":    
                    case "Disengaged":
                    case "Verified":  
                        return true;
                    case "Role":
                        return false;
                    case "Spamtrap":
                        return false;
                    case "Caution":
                        return false;
                    case "Unknown":
                        return false;
                    case "Invalid":
                        return false;

                }
            }) || 'does not validate'  
          },
          mobile: value => {
            const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            return pattern.test(value) || 'Invalid mobile.'
          },
        },
    }
  },
  async mounted(){
    //await this.GetNotifications();
  },
  watch:{
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
        console.log(newAddr)
        if(newAddr != oldAddr){
            //this.GetNotifications();
        }
    },
    
    pushEnabled: function( newPush){
        console.log(newPush)
        if(newPush == false){
            this.ForceShow();
        }
    },
    
  },
  methods: {
    GetHeaders(view){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view':view
          }
          return headers;
    },
    SetSms(){
        this.$emit("SetSms", this.sms)
    },
    SetSmsPref(){
        this.$emit("SetSmsPref", this.smsPreference)
    },
    async SetEmail(){
        if( await this.EmailServerValidate())
        {   this.emailError = "";
            this.$emit("SetEmail", this.email)
        } else {
            this.emailError = "This email address is not valid. Please enter a valid address"

        }
    },
    SetEmailPref(){
        this.$emit("SetEmailPref", this.emailPreference)
    },

    async EmailServerValidate(){
        var message_payload = {
            email: this.email,
        }

        return axios.post( "/.netlify/functions/Validation",message_payload, { headers: null} )
        .then(async function (response) {
            console.log(response)
            switch(response.data.result){
                case "Active":
                case "Inactive":
                case "Engaged":    
                case "Disengaged":
                case "Verified":  
                    return true;
                case "Role":
                    return false;
                case "Spamtrap":
                    return false;
                case "Caution":
                    return false;
                case "Unknown":
                    return false;
                case "Invalid":
                    return false;

            }
        })
    }

/*
    SaveEmail(){
        var saveEmail = {
            type: "email",
            contact: this.email
        }

        axios.post(".netlify/functions/Config",saveEmail, { headers: this.GetHeaders("saveemail")} )
            .then(async function (response) {
            console.log("SaveEmail: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    SaveSms(){
        var saveEmail = {
            type: "sms",
            contact: this.sms
        }

        axios.post(".netlify/functions/Config",saveEmail, { headers: this.GetHeaders("savesms")} )
            .then(async function (response) {
            console.log("SaveSms: " + response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    
    async GetNotifications(){
        var t = this;
        
        var d = await t.$store.dispatch("GetNotificationConfig", {})
        for(var i=0; i<d.length; i++){
            switch(d[i].type){
                case "email":   t.email = d[i].contact; break;
                case "sms":     t.sms = d[i].contact; break;
            }
        }
        t.ForceShow();
*/
        /*
        axios.post(".netlify/functions/Config",{}, { headers: this.GetHeaders("getnotifications")} )
        .then(async function (response) {
            var d = response.data;
            
            for(var i=0; i<d.length; i++){
                switch(d[i].type){
                    case "email":   t.email = d[i].contact; break;
                    case "sms":     t.sms = d[i].contact; break;
                }
            }
            t.ForceShow();
        })
        .catch(function (error) {
            console.log(error);
        });
        
    },
    ForceShow(){
        if(!(this.pushEnabled ||
           this.email == "" ||
           this.sms == "" )){
            this.$emit("ForceShow", true)
        } else {
            //this.$emit("ForceShow", false)
        }
    }  
    */  
  }
}
</script>

<style></style>