<template>
    <v-card>
      <v-card-title>Review the Deets</v-card-title>
      <v-card-subtitle>The following is YOUR crypto-wallet. Write the following words down. They are your password for your wallet. If you lose them, there is no recovery option. </v-card-subtitle>
      <v-card-text>
        <div style="margin-bottom:20px; text-align:center;"><v-btn @click="Download"><v-icon >mdi-download</v-icon> Download Passphrase</v-btn></div>

        <div class="text-h5" style="margin-top:30px;">My Interests</div>
        <div>{{ GetCategories() }}</div>

        <div class="text-h5" style="margin-top:30px;">My Wallet</div>
        <div>{{ addr }}</div>

        <div class="text-h5" style="margin-top:30px;">My Notifications</div>
          <ul>
            <li>Email: {{GetLabel(email)}}</li>
            <li>Mobile: {{GetLabel(sms)}}</li>
          </ul>
      

      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
</template>

<script>
//import axios from "axios";



export default {
  props:["name","selectedCard","selectedCategories","addr","email", "sms", "mnemonicPhrase"],
  data: () => {
    return {
    }
  },
  async mounted(){
    
  },
  watch:{
  },
  methods: {
    GetURL(){
      return "https://engagr.xyz/.netlify/functions/Image?name=" + this.name + "&bi=" + this.selectedCard
    },
    GetCategories(){
      var retStr = ""
      for(var i=0;i<this.selectedCategories.length; i++){
        retStr += this.selectedCategories[i].label + ", "
      }
      retStr = retStr.substring(0, (retStr.length - 2));
      return retStr;
    },
    GetLabel(label){
      if(label == ""){
        return "not set"
      } else {
        return label
      }
    },
    Download(){
        let filename = this.addr + '.txt';
        let text = this.mnemonicPhrase;
        
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    },    
  }
}
</script>

<style></style>