<template>
    <span>
    {{CutLength(profileName)}}
    </span>
</template>

<script>
//import axios from "axios";

export default {
  props: ["addr","maxLength"],
  data: () => {
    return {
      profileName: ""
    }
  },
  async mounted(){
    await this.LoadBits();
  },  
  watch: {
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
      if(newAddr != oldAddr){
        this.LoadBits()
      }
    }
  },
  methods: {
    async LoadBits(){
      await this.GetDomain();

    },
    CutLength(value){
      if(value==undefined) return "";
      if(this.maxLength &&
         value.length > this.maxLength)
        return value.substr(0,this.maxLength) + "…"
      
      return value;
    },
    async GetProfile( item ){
      return await this.$store.dispatch('fetchAddress', item )

      /*
      let profile = this.$store.getters.getProfile(item)

      if(profile){
        return profile
      }
*/
      //return null;
    },
    async GetDomain(){
      let profile = await this.$store.dispatch('fetchAddress', this.addr )
      this.profileName = profile?profile.name:"";
 
 
      },
    },
};
</script>

