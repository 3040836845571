<template>
<span style="background-color:transparent; width:800px;">
<v-overlay :value="overlay">
  <v-progress-circular
      :size="150"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
<v-container style="background-color:#ffffff">
    <v-row>
      <v-col class="text-h4 font-weight-bold" style="margin-top:10px; color:black;"><v-icon x-large>mdi-post</v-icon> Create your post</v-col>
    </v-row>

    <v-row>
      <v-col>


    <v-stepper
     v-model="activeStep"
    vertical
    class="elevation-0"
    >

    <v-stepper-step
      :complete="activeStep > 1"
      @click="Advance(1)"
      step="1"
    >
    <v-container>
        <v-row>
          <v-col>Content</v-col>
          <v-spacer></v-spacer>
          <v-col align="right" class="blue-grey--text text--lighten-4">
            <v-btn x-small v-if="hideContent!=true" outlined @click="showPromoDisplay()">
            <v-icon left>
              mdi-gift-outline
            </v-icon>
            PREVIEW</v-btn></v-col>
        </v-row>
      </v-container>

    </v-stepper-step>
  
    <v-stepper-content step="1">

      <v-card>
        <v-card-text>
          <v-select 
            v-model="postSelect"
            :items="postTypes"
            item-text="label"
            item-value="path"
            label="Post Type"
            @change="SelectPostType"
            return-object>
          </v-select>

        <!-- GroupMessageForm
          FormPurpose=""
          :addr="addr"
          :sessionGuid="sessionGuid"
          :sessionTxID="sessionTxID"
          :message="message"
          
          to="bulk"
          @changeSubject="changeSubject"
          @changeASA="changeASA"
          @changeBody="changeBody"
          @changeCTA="changeCTA"
          @closeWindow="closeWindow"
        ></GroupMessageForm -->

        <component 
          :is="PostType" 
          :message="message"
          @Valid="IsValid"
        ></component>
      </v-card-text>
      <v-card-actions>
          <v-btn outlined @click="showPromoDisplay">
            <v-icon left>
              mdi-gift-outline
            </v-icon>
            PREVIEW</v-btn>
          <v-spacer></v-spacer>

          <v-btn
          color="primary"
          @click="activeStep = 2"
          :disabled="hideContent"
        >
          Continue
        </v-btn>

        </v-card-actions>
      </v-card>

    </v-stepper-content>





    <v-stepper-step
      :complete="activeStep > 2"
      @click="Advance(2)"
      step="2"
    >
    <v-container>
        <v-row>
          <v-col>The Token</v-col>
          <v-spacer></v-spacer>
          <v-col align="right" class="blue-grey--text text--lighten-4"> {{ sendAsa }}</v-col>
        </v-row>
      </v-container>
    </v-stepper-step>

    <v-stepper-content step="2">

      <v-card full-width>
    <v-card-title>What you are sending?</v-card-title>
    <v-card-text>



      Reward Token: 
      <v-select
        v-model="defaultAlgo"
        :items="tokens"
        return-object
        @change="SelectToken(this)"
        >
        <template v-slot:selection="{ item }">
            {{ item.asset.name }}<v-chip text-color="white" color="grey" small
              >{{ item.asset.assetId }}</v-chip>
        </template>

        <template v-slot:item="{  item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                          <span>{{ item.asset.name }}</span>
                          <v-spacer></v-spacer>
                          <v-chip text-color="white" color="grey" small
                          >ASA ID:{{ item.asset.assetId }}</v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
      </v-select>

    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text
            @click="activeStep = 1">
          Back
        </v-btn>

        <v-btn
        color="primary"
          @click="activeStep = 3"
          :disabled="hideRewardType"
        >
          Continue
        </v-btn>

      </v-card-actions>
    </v-card>
    </v-stepper-content>


    <v-stepper-step
      :complete="activeStep > 3"
      @click="Advance(3)"
      step="3"
    >
    <v-container>
        <v-row>
          <v-col>The Reward</v-col>
          <v-spacer></v-spacer>
          <v-col align="right" class="blue-grey--text text--lighten-4">{{ totalReward }} {{ sendAsa }}</v-col>
        </v-row>
      </v-container>
    </v-stepper-step>

  <v-stepper-content step="3">

    <v-card full-width>
    <v-card-title>Your incremental spend</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col  class="col-12">
            Per reward
            <v-text-field
              v-model="perPerson"
              :suffix="sendAsa"
              @change="CheckReward"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>


    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text
            @click="activeStep = 2">
          Back
        </v-btn>
        <v-btn
        color="primary"
          @click="activeStep = 4"
          :disabled="hideCredits"
        >
          Continue
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-stepper-content>
  



    <!-- v-stepper-step
      :complete="activeStep > 4"
      @click="Advance(4)"
      step="4"
    >
      Schedule <span class="font-italc">(optional)</span>
    </v-stepper-step>

    <v-stepper-content step="4">

      <v-card>
        <v-card-text>

          <v-container>
            <v-row>
              <v-col cols="10">
                <v-datetime-picker
                  label="Pick a time to optionally schedule it."
                  v-model="scheduledTime">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>

              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-container>


        </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            @click="activeStep = 3">
          Back
        </v-btn>

          <v-btn
        color="primary"
          @click="activeStep = 5"
        >
          Continue
        </v-btn>
        </v-card-actions>
      </v-card>

        
    </v-stepper-content-->

    <v-stepper-step
      :complete="activeStep > 4"
      step="4"
    >
      Review & Send
    </v-stepper-step>

    <v-stepper-content step="4">

      <v-card>
        <v-card-text>

          <v-container>
            <v-row>
              <v-col>
                <v-checkbox v-model="NotifyFollowers">
                  <template v-slot:label>
                    Notify my {{ follows.followers }} followers about this post.
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">Header</v-col>
              <v-col>{{ message.header }}</v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">Reward/Recipient</v-col>
              <v-col>{{ perPerson }}</v-col>
            </v-row>
          </v-container>





        </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            @click="activeStep = 3">
          Back
        </v-btn>

          <v-btn
        color="primary"
          @click="send()"
        >
          Save
        </v-btn>
      </v-card-actions>
      </v-card>

        
    </v-stepper-content>
  </v-stepper>
    <v-btn
          color="primary"
            @click="Close()"
          >
            Close
          </v-btn>

</v-col>
</v-row>
</v-container>

<v-dialog v-model="showPromo" fullscreen>
  <v-card>
    <v-toolbar
      dark
      color="black"
    >
      <v-btn
        icon
        dark
        @click="showPromo = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title><v-img width="100" src="../../src/assets/engagr-sm.png"></v-img></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-row><v-col></v-col></v-row>
      <v-row>
        <v-col>
          <v-sheet
            elevation="10">
            
            <MessageReward
              :message="message"  
              :sessionGuid="sessionGuid"
              :sessionTxID="sessionTxID"
              :messageID="messageID"                >
            </MessageReward>

          </v-sheet>

        </v-col>
      </v-row>
    </v-container>
  </v-card>
</v-dialog>
</span>
</template>

<script>
//import Vue from 'vue';
import axios from "axios";
import { VueBus } from '../plugins/vuebus.js';


import GroupMessageForm from "./Group-Message-Form.vue";
import MessageReward from "./Message-Reward.vue";




export default {
name: "PostCreate",
components: {
GroupMessageForm,
MessageReward,
},
props: ["addr","sessionGuid","sessionTxID"],

data: () => {
return {
  message:{},
  deploymentName: "",
  audienceType: "",
//  query: "",
//  queryHolders: [],
//  finalQuery: "",
  queryFeedback: "",
  hideName: true,
  hideRecipients: true,
  hideCredits: true,
  hideContent: true,
  hideRewardType: true,
  showPromo: false,
  Profiles: {},
  perPerson:"1",
  totalReward:0,
  recipients:[],
  recipientCount: 0,
  queryResult: {},
  search:"",
  activeStep: 1,
  sendAsa: "algo",
  sendAsaID: 0,
  defaultAlgo: {name:"algo",id:"12345"},
  decimalCount:3,
  tokens: null,
  scheduledTime: null,
  busy: true,
  connected: true,
  showWallet: true,
  messageID: "fake",
  overlay: false,
  follows: {
    followers:0,
    following:0
  },

  NotifyFollowers: false,
  path: "https://worker.hokr.workers.dev/api/",
  postTypes: [
          { label: 'CTA', path: 'Info-Type.vue', type:"cta" },
          { label: 'Wordle', path: 'Wordle-Type.vue', type:"wordle" },
        ],
  postSelect: { label: 'Info', path: 'Info-Type.vue', type:"cta" },
  loadedComponents:{},
  myBrand: {}
  
}
},
computed:{
  PostType(){
    console.log("POST TYPE")
    console.log(this.postSelect)
    return () => import("./postTypes/"+this.postSelect.path)
  }

},
watch:{
  addr(oldVal, newVal){
    if(oldVal != newVal){
      if(this.addr){
        this.LoadFungibles()
      }
    }

  },
  recipientCount(){
    this.updateByPerson();
  }
},
async mounted(){
  console.log("MOUNTED")
  this.follows = await this.$store.dispatch('fetchFollows', this.addr.toUpperCase() )

  if(this.addr){
    this.LoadFungibles();
  }
  //this.LoadBrand();
},  
methods: {
getTotalSendCost(){
  if(this.sendAsa == "algo")
    return parseFloat(this.totalReward) + parseFloat(this.recipientCount * .02) + parseFloat(this.recipientCount * .001)
  else
    return parseFloat(this.recipientCount * .02) + parseFloat(this.recipientCount * .001)
},
async LoadBrand(){
    
    let brand = await this.$store.dispatch('fetchBrand', this.addr.toUpperCase() )
    this.myBrand = brand;
  },

 showPromoDisplay(){
  console.log(this.message)
  
  //this.showPromo=true

 },

getDecimalCount(val){
  if(val.indexOf(".")!= -1){
    var parts = val.split(".")

    return parts[1].length;
  }
  return 0;
},

async send(){
  this.overlay = true;
  for(var prop in this.message){

    if(typeof this.message[prop]=="object" && 
        this.message[prop].type &&
        ( this.message[prop].type == "image/png" ||
          this.message[prop].type == "image/jpg" ||
          this.message[prop].type == "image/jpeg" ||
          this.message[prop].type == "image/gif" 
        )
    ){
      //upload image and replace with aCID

      var cid = await this.PostImageForCID(this.message[prop])
      console.log(cid)
      this.message[prop] = cid;
      }
    }

    var id=crypto.randomUUID();
    this.message.id=id;

    var me = this;
    var message_payload = {
        sender: this.addr,
        perPerson: parseFloat(this.perPerson),
        totalPaid: 0,
        follow: null,
//        scheduledDate: this.scheduledTime,
        createdDate: new Date(),
        message: this.message,
        asaid: parseInt(this.sendAsaID),
        asaName: this.sendAsa,
        likeCount: 0,
        paidCount: 0,
        id: id,
        status: "NEW"

      }

  //change to CF save

  var headers = {
          'sessionGuID': this.sessionGuid,
          'sessionTxID': this.sessionTxID,
          'walletID': this.addr,
      }

      let url = this.path + "user/" + this.addr+ "/post"
      axios.post( url, message_payload, { headers: headers} )

      .then(async function (response) {
        me.posts = await me.$store.dispatch('fetchFeed', me.addr.toUpperCase(), true )

        console.log(response.data)
        me.overlay = false;
        me.$emit("CloseCreate", response.data)
      })
      
    },
    Close(){
      console.log("CloseCreate")
      this.$emit("CloseCreate")
    },
    async PostImageForCID(img){
      var message_payload = {
        uploadedImage: img
      }

      var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "saveimage",
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }

        return await axios.post("/.netlify/functions/Message",message_payload, { headers: headers} )
            .then(async function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    closeWindow(){
      console.log("CLose window")
    },
    SetCampaignName(){
      if(this.deploymentName == ""){
        this.hideName = true;
      } else {
        this.hideName = false;
      }
    },
    changeASA(val){
      this.message.messageDeets.a = val;
    },
    changeBody(val){
      console.log(val)
      this.message.messageDeets.b = val
    },
    changeCTA(val){
      this.message.messageDeets.c = val
    },
    Advance(step){
      if(this.activeStep>=step)
        this.activeStep = step
    },
    GetTitle(){
      return "Me title"
    },
    async LoadFungibles(){
      console.log("LOAD FUNGIES")
      this.tokens = await this.$store.dispatch('fetchTokens', this.addr );
      console.log(this.tokens)
      if(this.tokens.length == 0){
        this.$emit("CloseCreate", null)
        VueBus.$emit("Nav", "/loyalty" )
      }
      },
      SelectToken(){
        this.hideRewardType = false;
        console.log("TOKEN SELECT ")
        this.sendAsa      = this.defaultAlgo.asset.name
        this.sendAsaID    = this.defaultAlgo.asset.assetId
        this.decimalCount = this.defaultAlgo.asset.decimalCount

        console.log(this.sendAsaID)
      },
      CheckReward(){
        if( parseFloat(this.perPerson) ){
          this.hideCredits = false;
        } else {
          this.hideCredits = true;
        }
      },
      SelectPostType(item){
        this.$set(this.postSelect, 'label', item.label)
        this.$set(this.postSelect, 'path', item.path)

        //clear previous
        this.message = {};

        //set the type on the obejct
        this.message.type=item.type;
      },
      IsValid(message){
        this.message = message;
        this.hideContent = false;
      }
  }        
  
}

</script>

