<template>
            <v-card>
                <v-card-title>Create Loyalty Token</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col><v-text-field label="$Token Name" v-model="coinName"></v-text-field></v-col>
                        </v-row>
                        <v-row>
                            <v-col><v-text-field label="Unit Name"  v-model="unitName" ></v-text-field></v-col>
                            <v-col><v-text-field label="Total Points"  v-model.number="tokenCount" ></v-text-field></v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea      
                                v-model="coinDescription"
                                label="Description"
                                auto-grow
                                model-value=""
                                ></v-textarea>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col>
                                <div class="text-label">Select an image to represent your token</div>
                                <v-file-input 
                                    accept="image/*"
                                    prepend-icon="mdi-camera"
                                    @change="SelectImage"
                                    @click:clear="ClearImage"
                                    v-model="currentImage" />
                                <v-img 
                                    max-width="250"
                                    :src="url" />
                            </v-col>
                        </v-row>
                    </v-container>
                    
                    
                    

                </v-card-text>
                <v-card-actions><v-spacer></v-spacer>
                    <v-btn @click="SaveFungible" class="primary">Save</v-btn>
                    <v-btn @click="HideCreate">Cancel</v-btn></v-card-actions>
            </v-card>
    
</template>

<script>
import axios from "axios";

export default {
  props: ["addr","sessionGuid","sessionTxID"],
  data: () => {
    return {
        currentImage: null,
        url: "",
        tokenCount: 1000000,
        unitName: "",
        coinName: "",
        coinDescription: "",
        tokens:[],
    }


  },
  mounted(){
    
  }, 
  watch: {
    // whenever addr changes, this function will run
    sessionTxID: function(newAddr, oldAddr) {
      if(newAddr != oldAddr){
        //this.LoadFungibles()
      }
    }
  },
  methods: {
        async SelectImage(image){
            this.currentImage = image;
            this.url = URL.createObjectURL(this.currentImage);
        },
        ShowCreate(){
            console.log("show create")
            this.create = true;
        }, 
         ClearImage(){
            this.currentImage = null;
            this.url = "";
        },
        HideCreate(){
            this.$emit("CloseFT")
        },
        /*
        DeleteToken(AssetID){
            var me = this;
            
            var thing ={
                assetID: parseInt(AssetID)
            }
            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "deletefungible",
                'Accept': 'application/json',
            }
            axios.post( ".netlify/functions/Config",
                thing, 
                { 
                    headers: headers
                } )
            .then(async function (response) {
                me.tokens = response.data.data.accountAssets.nodes;
            })

        },
        */
        LoadAsset(assetID){
            window.location='https://algoexplorer.io/asset/' + assetID
        },

        SaveFungible(){
            this.create = false;
            var me = this;

            var payload = {
                "name": this.coinName,
                "unitName": this.unitName,
                "description": this.coinDescription,
                "tokenCount": this.tokenCount,
                "image": this.currentImage
            }

            var headers = {
                'sessionGuID': this.sessionGuid,
                'sessionTxID': this.sessionTxID,
                'walletID': this.addr,
                'view': "savecoin",
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
            axios.post( ".netlify/functions/Config",
                payload, 
                { 
                    headers: headers
                } )
            .then(async function (response) {
                me.tokens = response.data.data.accountAssets.nodes;  
                this.$emit("CloseFT")          
            })
        },
        nFormatter(num, digits) {
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "k" },
                { value: 1e6, symbol: "M" },
                { value: 1e9, symbol: "G" },
                { value: 1e12, symbol: "T" },
                { value: 1e15, symbol: "P" },
                { value: 1e18, symbol: "E" }
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            var item = lookup.slice().reverse().find(function(item) {
                return num >= item.value;
            });
            return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
        }


    }
};
</script>

