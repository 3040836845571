<template>
    <v-card >
      <v-card-subtitle>
        


        The following is YOUR wallet. Download and store the following passphrase (words). They are your password for your wallet. If you lose them, there is no recovery option. </v-card-subtitle>
      <v-card-text>
        <div class="text-h5" title="Your account number">Your Wallet <v-btn @click="CopyAddress" ><v-icon sm >mdi-content-copy</v-icon></v-btn></div>
        <div class="text-subtitle-1" style="margin-bottom:20px;">{{ wallet.addr }} </div>
        
        <div title="Copy Passphrase" class="text-h5">Your Passphrase <v-btn @click="CopyMnemonic"><v-icon sm>mdi-content-copy</v-icon></v-btn></div>
        <v-container>
            <v-row>
                <v-col cols="12" xs="12" sm="3"   v-for="item in GetKeys()" :key="item.mnemonic">
                    {{ item }}
                </v-col>
            </v-row>
        </v-container>
        <div style="margin-bottom:20px; text-align:center;"><v-btn @click="Download"><v-icon >mdi-download</v-icon> Download Passphrase</v-btn></div>



      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
</template>

<script>
import axios from "axios";



export default {
  props:["addr","sessionGuid","sessionTxID","pushEnabled",],
  data: () => {
    return {
        name: "",
        username: "",
        password:"",
        showPassword: false,
        email: "",
        wallet: {
            addr:"Not yet created",
            mnemonic: "",
        },
        sms: "",
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          mobile: value => {
            const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            return pattern.test(value) || 'Invalid mobile.'
          },
        },
    }
  },
  async mounted(){
    await this.CreateAccount();
  },
  watch:{
    // whenever addr changes, this function will run
    addr: function(newAddr, oldAddr) {
        console.log(newAddr)
        if(newAddr != oldAddr){
            //this.GetNotifications();
        }
    },
    
    pushEnabled: function( newPush){
        console.log(newPush)
        if(newPush == false){
            this.ForceShow();
        }
    },
    
  },
  methods: {
    GetHeaders(view){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr,
              'view':view
          }
          return headers;
    },
    SetName(){
      this.$emit("SetName", this.name)
    },
    SetUsername(){
      this.$emit("SetUsername", this.username)
    },
    SetPassword(){
      this.$emit("SetPassword", this.password)
    },

    CreateAccount(){
        var me = this;

        
        axios.post("/.netlify/functions/Config", null, { headers: this.GetHeaders("createwallet")} )
            .then(async function (response) {
            me.wallet = response.data;

            me.$emit("SetWallet", me.wallet)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    CopyAddress(){
        navigator.clipboard.writeText( this.wallet.addr )
    },
    CopyMnemonic(){
        navigator.clipboard.writeText( this.wallet.mnemonic )
    },
    Download(){
        let filename = this.wallet.addr + '.txt';
        let text = this.wallet.mnemonic;
        
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    },
    GetKeys(){
        return this.wallet.mnemonic.split(" ")
    },
  }
}
</script>

<style></style>