<template>
      <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
      <v-btn raised color="yellow" large>
        <v-img src="../assets/pera-logo-black.png" width="80"  class="btn btn-success" v-on:click="PeraConnect()"></v-img>
      </v-btn>
    </v-col>

          <v-col>
      <v-btn raised large>
        <v-img src="../assets/defly.png" width="80"  class="btn btn-success" v-on:click="DeflyConnect()"></v-img>
      </v-btn>
    </v-col>
    
    <v-spacer></v-spacer>
    </v-row>
    </v-container>
    
</template>

<script>
export default {
  props: ["addr", "showWallet"],
  data: () => {
    return {
    }
  },
  methods: {
    DeflyConnect: function () {
      console.log("conn");
      this.$emit("DeflyConnect");
    },
    PeraConnect: function () {
      console.log("Pera");
      this.$emit("PeraConnect");
    },
  },
};
</script>

