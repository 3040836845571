// src/plugins/vuetify.js

import Vue from 'vue'
import vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

//import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
//import 'tiptap-vuetify/dist/main.css'


Vue.use(vuetify)

export default new vuetify()