<template>
  <div style="padding:20px;">
    <div class="text-h3" style="margin-bottom:15px;">{{ message.messageDeets.s }}</div>
    <div style="margin-bottom:20px;" v-if="message.messageDeets.a!=''">
        <NFTView 
            width="200"
            :sessionGuid="sessionGuid"
            :sessionTxID="sessionTxID"
            :walletID="addr"
            :asaid="message.messageDeets.a">
        </NFTView>
    </div>

      <div style="padding:10px;" v-html="GetContent(message)"></div>
      <!-- div style="padding:10px;" v-html="message.messageDeets.b"></div -->
      <!-- vue-markdown 
        show 
        breaks    
        :source="message.messageDeets.b"></vue-markdown -->
      

      <div v-if="message.messageDeets.c"
        class="text-center">
            <v-btn 
            large
            :elevation="hover ? 24 : 2"
            dark  
            color="primary"
            @click="ClickCall">
                See it
            </v-btn>
      </div>
    </div>
</template>

<script>
//import axios from "axios";
import NFTView from "./NFT-View.vue";
import * as showdown from 'showdown';

//import AvatarView from "./Avatar-View.vue";
import axios from "axios";


export default {
  name: "MessageReward",
  components:{
    NFTView,
//    AvatarView
  },
  props: ["addr","buddyID","sessionGuid","sessionTxID","message", "tracking","messageID"],
  data: () => {
    return {
    }
  },
  mounted(){
    console.log(this.message)
  },  

  methods: {
    GetContent(message){
      console.log(message.messageDeets)

      const converter = new showdown.Converter();
      
      return converter.makeHtml(message.messageDeets.b);

    },
    ClickCall(){
      console.log("CLICK")
      //if(this.to != this.addr){
        var headers = {
              'sessionGuID': this.sessionGuid,
              'sessionTxID': this.sessionTxID,
              'walletID': this.addr
          }

        var message_payload = {
              "u": this.addr,
              "d": {
                "i":this.message.assetID
              },
              "t": "click"
            }
            console.log(this.message.messageDeets.t)
            console.log(this.addr)
        var t = this;
        console.log(t)

        if( this.addr == this.message.messageDeets.t){
          axios.post(".netlify/functions/LogAction",message_payload, { headers: headers} )
          .then(async function (response) {
            console.log("LOGGEDCLICK: " + response.data)
            //location.href = t.message.messageDeets.c;
          })
          .catch(function (error) {
              console.log("BAD ERROR")
                console.log(error);
          });
          //console.log("MEOW")
          //console.log(this.message.messageDeets.c)
          
          //window.open(this.message.messageDeets.c )
        }
        
    },
  }
    
    
};
</script>

