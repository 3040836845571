<template>
  

  <div style="margin:0px;padding:0px; padding-top:15px; border-bottom: 1px solid #bbbbbb; ">
    <component 
      :is="PostType"
      :showIcon="showIcon"
      :postItem="postItem"
      @CallItem="CallItem"
    ></component>  

    <br/>
    <v-app-bar elevation="0" v-if="postItem">
      <v-btn
        icon
        :color="IconColor()"
        @click="LikePost"
      >
        <v-icon          
          >mdi-heart</v-icon>
      </v-btn>

      <span class="text-caption">{{ postItem.likeCount }} likes</span>
      <v-spacer></v-spacer>
      <v-icon
        :color="CurrencyColor()"          
          >mdi-currency-usd</v-icon>
      <span class="text-caption">{{ postItem.paidCount }} paid</span>
    </v-app-bar>

  </div>
  

</template>

<script>
import { VueBus } from '../plugins/vuebus.js';

export default {
  name: "PostItem",
  props: ["postItem","brand","addr","sessionGuid","sessionTxId","showIcon"],

  data: () => {
    return {
      postTypes: {
        "default":{ path: 'Blank-Type.vue', "type":"cta"},
        "cta":{ label: 'CTA', path: 'Info-Type.vue', type:"cta" },
        "wordle":{ label: 'Wordle', path: 'Wordle-Type.vue', type:"wordle" },
      }
    }
  },
  computed:{
    PostType(){
      
      if(this.postItem)
        return () => import("./postTypes/view/"+this.postTypes[this.postItem.message.type].path);
      else 
        return () => import("./postTypes/view/Blank-Type.vue")    
    }
  },
  methods:{
    ShowDelete(){
      if(this.brand && (this.brand.userType=="brand" || this.brand.userType=="admin") && this.addr == this.brand.senderID){
        return true
      } else {
        return false
      }
    },
    LikePost(){      
      let postID = this.postItem.id
      let sender = this.postItem.sender
 
      let url = "/user/" + sender + "/post/"+postID+"/like";
      let params = {
        actionUserID: this.addr
      }
      let method = "POST"
      let action = "like that post"

      let finish = "UpdatePosts"
      let finishID = sender

      //let profile = await this.$store.dispatch('membershipGate', method, url, params )

      //this.$emit("MembershipGate", method, url, params)
      VueBus.$emit('MembershipGate',action, method, url, params, finish, finishID);
      
    },
    CallItem(){
      let postID = this.postItem.id
      let sender = this.postItem.sender

      let url = "/user/" + sender + "/post/"+postID+"/pay";
    let params = {
          walletID: this.addr
      }

      let method = "POST"
      let action = "reward that post"
      let finish = "UpdatePosts"
      let finishID = sender


      //let profile = await this.$store.dispatch('membershipGate', method, url, params )
      VueBus.$emit('MembershipGate',action, method, url, params, finish, finishID);

      if(this.postItem.message && this.postItem.message.cta)
          window.open(this.postItem.message.cta)

    },
    IconColor(){
      if(this.postItem && this.postItem.liked==true){
        return "pink"       
      } else {
        return "grey"
      }
      
    },
    CurrencyColor(){
      if(this.postItem && this.postItem.isPaid==true){
        return "green"       
      } else {
        return "grey"
      }
      
    },

  }
}
</script>